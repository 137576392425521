<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title nb">
        <div class="title-word">寄送資訊</div>
      </h2>
      <ul class="address-tab" role="tablist">
        <li
          :class="{ active: index == 0 }"
          role="presentation"
          v-for="(tab, index) in taketabs"
          :key="`tabk${index}`"
          @click="currentList = index"
        >
          <a
            :href="`#tab0${index + 1}`"
            aria-controls="home"
            role="tab"
            data-toggle="tab"
          >
            {{ tab }}
          </a>
        </li>
      </ul>
      <div class="tab-content-wrapper form-block-wrap">
        <div
          v-for="(orderContact, index) in addressList"
          :key="index"
          class="tab-content tab-pane fade in"
          :class="{ active: index == 0 }"
          :id="`tab0${index + 1}`"
          role="tabpanel"
        >
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入姓名"
              v-model="orderContact.name"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              v-model="orderContact.email"
              placeholder="請輸入信箱"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              v-model="orderContact.address"
              placeholder="請輸入地址"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入市話"
              v-model="orderContact.phone"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入手機1"
              v-model="orderContact.mobile"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入手機2"
              v-model="orderContact.mobile_two"
            />
          </div>
          <div class="input-wrap">
            <textarea
              type="text"
              placeholder="請輸入備註"
              v-model="orderContact.remark"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-block-wrap">
        <div>
          <div class="radio-box">
            <input
              type="radio"
              name="invoice"
              value="1"
              id="doubleInvoice"
              v-model="orderInvoice.invoice"
              @change="invoiceTypeChanged"
            />
            <label for="doubleInvoice">二聯式發票(個人)</label>
          </div>
          <div class="radio-box">
            <input
              type="radio"
              name="invoice"
              value="2"
              id="tripleInvoice"
              v-model="orderInvoice.invoice"
              @change="invoiceTypeChanged"
            />
            <label for="tripleInvoice">三聯式發票(公司行號)</label>
          </div>
          <div class="radio-box">
            <input
              type="radio"
              name="invoice"
              value="3"
              id="donate"
              v-model="orderInvoice.invoice"
              @change="invoiceTypeChanged"
            />
            <label for="donate">捐贈發票</label>
          </div>
        </div>

        <div>
          <div v-if="orderInvoice.invoice === '1'">
            <div class="dashed"></div>
            <div>
              <div class="radio-box">
                <input
                  type="radio"
                  name="carrier"
                  value="1"
                  id="memberCarrier"
                  v-model="orderInvoice.carrier"
                  @change="carrierChanged"
                />
                <label for="memberCarrier">會員載具</label>
              </div>
              <div class="radio-box">
                <input
                  type="radio"
                  name="carrier"
                  value="2"
                  id="naturalCarrier"
                  v-model="orderInvoice.carrier"
                  @change="carrierChanged"
                />
                <label for="naturalCarrier">自然人憑證載具</label>
              </div>
              <div class="radio-box">
                <input
                  type="radio"
                  name="carrier"
                  value="3"
                  id="mobileCarrier"
                  v-model="orderInvoice.carrier"
                  @change="carrierChanged"
                />
                <label for="mobileCarrier">手機載具</label>
              </div>
            </div>
            <div class="carrier-input-box">
              <div class="input-wrap">
                <input
                  type="text"
                  :placeholder="doubleInvoicePlaceholder"
                  v-model="orderInvoice.carrier_info"
                />
              </div>
            </div>
          </div>

          <div v-if="orderInvoice.invoice === '2'" class="carrier-input-box">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入公司行號抬頭"
                v-model="orderInvoice.company_title"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入統一編號"
                v-model="orderInvoice.tax_id"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入電子郵件"
                v-model="orderInvoice.carrier_email"
              />
            </div>
          </div>

          <div v-if="orderInvoice.invoice === '3'" class="carrier-input-box">
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入愛心碼"
                v-model="orderInvoice.love_code"
              />
            </div>
          </div>
        </div>
        <div>發票一經開立不得任意更改或改開發票。</div>
      </div>
    </div>
    <div class="bottom-btn-block">
      <a class="btn-cta" @click.prevent="submit">提交</a>
    </div>
  </div>
</template>
<script>
import { createOrder, checkoutOrder } from '@/api/order';
import { mapActions, mapState } from 'vuex';
import { finishedStep2 } from '@/api/group-buying';
import FollowSettingMixin from '@/mixins/follow-setting';
import memberSettingsMixin from '@/mixins/member-settings';

export default {
  mixins: [FollowSettingMixin, memberSettingsMixin],
  data() {
    return {
      currentList: 0,
      taketabs: ['地址一', '地址二', '地址三'],
      orderInvoice: {
        invoice: '1',
        carrier: '1',
        carrier_info: null,
        love_code: null,
        company_title: null,
        tax_id: null,
        carrier_email: null
      },
      orderContact: {
        name: null,
        address: null,
        phone: null,
        mobile: null,
        mobile_two: null,
        remark: null
      }
    };
  },
  computed: {
    ...mapState('user', ['email']),
    groupId() {
      return this.$route.params.group_id;
    },
    doubleInvoicePlaceholder() {
      if (this.orderInvoice.carrier === '1') {
        return '請輸入電子郵件';
      } else if (this.orderInvoice.carrier === '2') {
        return '請輸入16碼大寫英數字';
      } else {
        return "請輸入開頭'/'之大寫英數字";
      }
    }
  },
  methods: {
    ...mapActions('user', ['getInfo']),
    invoiceTypeChanged() {
      this.orderInvoice.company_title = null;
      this.orderInvoice.tax_id = null;
      this.orderInvoice.carrier_email = null;
      this.orderInvoice.love_code = null;
      this.orderInvoice.carrier_info = null;
      if (this.orderInvoice.invoice != 1) {
        this.orderInvoice.carrier = null;
      } else if (
        this.orderInvoice.invoice == '1' &&
        this.orderInvoice.carrier == null
      ) {
        this.orderInvoice.carrier = '1';
      }
    },
    carrierChanged() {
      this.orderInvoice.carrier_info = null;
    },
    submit() {
      this.orderContact = this.addressList[this.currentList];

      createOrder({
        group_buying_order_id: this.groupId,
        order_contact: this.orderContact,
        order_invoice: this.orderInvoice
      }).then(data => {
        finishedStep2({
          group_buying_order_id: this.groupId
        }).then(() => {
          this.backToManageMenu();
        });

        // 結帳訂單
        checkoutOrder(data.no).then(data => {
          document.write(data.html);
        });
      });
    },
    backToManageMenu() {
      this.$router.push({
        name: 'CreatedGroupManage',
        params: this.$route.params
      });
    }
  },
  mounted() {
    this.getInfo();
  }
};
</script>
